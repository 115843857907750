<template>
  <div class="bg-white">
    <v-navigation-drawer absolute temporary v-model="openMenu">
      <side-menu />
    </v-navigation-drawer>
    <loading-spinner />
    <error-message-dialog />
    <que-info-dialog
      ref="queInfoRef"
      :deferredMethod="
        () => {
          // 検索
          this.search();
        }
      "
    />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_exitingRequestAddConfirm" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="mt-1 d-flex gap-3">
            <!-- 出荷先 -->
            <c-ship-to-input
              v-model="searchForm.shipToSid"
              :clientSid="searchForm.shipToClientSid"
              required
            />
            <!-- 出荷予定日 -->
            <c-date-picker
              v-model="searchForm.outScheduleDate"
              required
              :label="$t('label.lbl_shippingScheduleDate')"
            />
            <!-- 輸送会社名 -->
            <c-carrier-comp-input
              v-model="searchForm.truckInfo"
              :clientSid="searchForm.clientSid"
              required
            />
          </v-row>
          <!-- 2行目 -->
          <v-row class="d-flex justify-space-between gap-3 align-center">
            <!--ボタン領域-->
            <div>
              <!-- 戻るボタン -->
              <v-btn color="primary" id="btn-search" class="other-btn ml-0" @click="backBtn()">{{
                $t("btn.btn_back")
              }}</v-btn>
            </div>
            <div>
              <!-- 出庫依頼登録ボタン -->
              <v-btn
                :color="'primary'"
                id="btn-search"
                class="other-btn-nosize"
                @click="exitingRequestAddBtn()"
                :disabled="pagination.totalCount == 0"
                >{{ $t("label.lbl_exitingRequestAdd") }}</v-btn
              >
            </div>
          </v-row>
          <!-- 4行目 -->
          <v-row class="d-flex justify-space-between gap-3 align-center pt-2">
            <div>
              <!--検索結果件数表示-->
              <span>{{
                $t("label.lbl_total") + "：" + pagination.totalCount + $t("label.lbl_number")
              }}</span>
            </div>
            <!--表示件数切替ボタン-->
            <v-btn-toggle
              v-model="pagination.itemsPerPage"
              color="blue darken-2"
              class="toggle black--text"
              right
              mandatory
            >
              <v-btn class="pasing-btn" :value="100" small>
                {{ 100 }}
              </v-btn>
              <v-btn class="pasing-btn" :value="250" small>
                {{ 250 }}
              </v-btn>
              <v-btn class="pasing-btn" :value="500" small>
                {{ 500 }}
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="pagination.currentPage"
          :items-per-page="pagination.itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="575px"
        >
          <!-- 所属 -->
          <template v-slot:[`item.officialName`]="{ item }">
            <c-table-column-tooltip v-model="item.officialName" />
          </template>
          <!-- 部品要求者 -->
          <template v-slot:[`item.userName`]="{ item }">
            <c-table-column-tooltip v-model="item.userName" />
          </template>
          <!-- グループ -->
          <template v-slot:[`item.groupName`]="{ item }">
            <c-table-column-tooltip v-model="item.groupName" />
          </template>
          <!-- 品番/品名 -->
          <template v-slot:[`item.item`]="{ item }">
            <c-table-column-tooltip v-model="item.item" />
          </template>
          <!-- 倉庫 -->
          <template v-slot:[`item.warehouseName`]="{ item }">
            <c-table-column-tooltip v-model="item.warehouseName" />
          </template>
          <!-- ロケーション -->
          <template v-slot:[`item.locationNo`]="{ item }">
            <c-table-column-tooltip v-model="item.locationNo" />
          </template>
          <!-- 管理番号 -->
          <template v-slot:[`item.itemManageNo`]="{ item }">
            <c-table-column-tooltip v-model="item.itemManageNo" :maxWidth="205" />
          </template>
          <!--入り数 -->
          <template v-slot:[`item.stock`]="{ item }">
            <c-table-column-tooltip v-model="item.stock" />
          </template>
          <!-- 保管期限 -->
          <template v-slot:[`item.retentionDuedate`]="{ item }">
            <c-table-column-tooltip v-model="item.retentionDuedate" />
          </template>
          <!-- 品質区分 -->
          <template v-slot:[`item.qualityName`]="{ item }">
            <c-table-column-tooltip v-model="item.qualityName" />
          </template>
          <!-- 削除ボタン -->
          <template v-slot:[`item.delete`]="{ item, index }">
            <v-btn small @click="deleteItem(index)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.currentPage"
            :length="pagination.totalPage"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="ConfirmDialog.isOpen"
      :title="ConfirmDialog.title"
      :message="ConfirmDialog.message"
      :screenFlag="ConfirmDialog.screenFlag"
      :changeFlag="ConfirmDialog.changeFlag"
      :okAction="ConfirmDialog.okAction"
    />
  </div>
</template>

<script>
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { httpClient } from "@/assets/scripts/js/HttpClient";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import ConfirmDialog from "@/components/ConfirmDialog";
import QueInfoDialog from "@/components/QueInfoDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import paginationMixin from "@/mixins/PaginationMixin.js";

export default {
  name: appConfig.SCREEN_ID.P_EXT_102,
  mixins: [commonMixin, commonRules, paginationMixin],
  components: {
    ConfirmDialog,
    QueInfoDialog,
  },
  data: () => ({
    appConfig,
    // 検索フォーム
    searchForm: {
      // 取引先
      clientSid: "",
      // 出荷先用の取引先SID
      shipToClientSid: "",
      // 出荷先
      shipToSid: "",
      // 出荷予定日
      outScheduleDate: "",
      // 輸送会社名
      truckInfo: {},
    },
    // メニュー
    openMenu: null,
    dateMenu: false,
    // テーブルフッター項目
    inputList: [],
    // テーブルヘッダ項目
    headerItems: [
      // 所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "officialName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "userName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // グループ
      {
        text: i18n.tc("label.lbl_groupName"),
        value: "groupName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "item",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouseName",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "locationNo",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 管理番号
      {
        text: i18n.tc("label.lbl_managementNoJP"),
        value: "itemManageNo",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "stock",
        align: "center",
        width: "8%",
        sortable: false,
      },
      // 保管期限
      {
        text: i18n.tc("label.lbl_retentionDuedate"),
        value: "retentionDuedate",
        align: "left",
        width: "8%",
        sortable: false,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "qualityName",
        align: "left",
        width: "8%",
        sortable: false,
      },
      // 削除
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    // 初期化
    init() {
      // ページング：リフレッシュ処理
      this.pagination.afterFunction = this.search.bind(null);
      // パラメータ
      Object.assign(this.searchForm, this.$route.params);
      // 検索
      this.search();
      // 出荷予定日
      this.searchForm.outScheduleDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
    },

    // 検索
    search() {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 仮登録リスト取得処理
      const config = httpClient.createGetApiRequestConfig();
      // 検索条件
      Object.assign(config.params, this.searchForm);
      // ページング
      Object.assign(config.params, {
        reqComPaginationFlg: "1",
        reqComPageIndex: this.pagination.currentPage,
        reqComPageLimit: this.pagination.itemsPerPage,
      });
      return new Promise((resolve, reject) => {
        httpClient
          .secureGet(appConfig.API_URL.BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_PARTS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.results.forEach((row) => {
                list.push({
                  ...row,
                  // 品番/品名
                  item: [row.itemCd, row.itemName],
                  // 入り数
                  stock: commonUtil.formatToCurrency(Number(row.stock ?? 0)),
                });
              });
              this.inputList = list;
              // 総件数
              this.pagination.totalCount = jsonData.resCom.resComPagination.totalRecord;
              // 総ページ数
              this.pagination.totalPage = jsonData.resCom.resComPagination.totalPage;
              // 検索値保持
              Object.assign(this.searchFilter, this.searchForm);
              resolve();
            } else {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = jsonData.resCom.resComMessage;
              }
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },

    // 戻る
    backBtn() {
      // 一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_EXT_101,
        params: {},
      });
    },

    // 出庫依頼登録ボタン
    exitingRequestAddBtn() {
      // バリデーション
      if (this.$refs.form.validate()) {
        this.$refs.queInfoRef.open({
          reqCom: { reqComComponentId: this.$options.name },
          reqIdv: {
            clientSid: this.searchForm.clientSid,
            shipToSid: this.searchForm.shipToSid,
            outScheduleDate: dateTimeHelper.convertUTC(this.searchForm.outScheduleDate),
            truckInfo: this.searchForm.truckInfo,
          },
        });
      }
    },

    // 削除
    deleteItem(index) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 対象行
      const item = this.inputList[index];
      // 仮登録リスト削除処理
      const config = httpClient.createGetApiRequestConfig();
      // 取引先SID
      config.params.clientSid = this.searchForm.clientSid;
      // 管理No
      config.params.itemManageNoList = [item.itemManageNo];
      return new Promise((resolve, reject) => {
        httpClient
          .secureDelete(appConfig.API_URL.BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_DELETE_PARTS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const lastPageFlg =
                this.pagination.currentPage == this.pagination.totalPage &&
                this.inputList.length == 1;
              if (this.pagination.currentPage != 1 && lastPageFlg) {
                this.pagination.currentPage = this.pagination.currentPage - 1;
              }
              // 検索
              this.search();
              resolve(jsonData.resIdv.results);
            } else {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = jsonData.resCom.resComMessage;
              }
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {},
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 0 0 16px;
}
</style>
