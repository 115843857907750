<template>
  <!-- キュー状況ダイアログ -->
  <v-dialog v-model="isOpen" :max-width="800" persistent="" no-click-animation>
    <v-card>
      <v-card-title id="sub-bar">
        <span id="lbl-screen-name">{{ $t("label.lbl_processingsituatuion") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <!-- キュー状況テーブルデータ -->
          <v-data-table
            id="QueInfoStatusData"
            fixed-header
            :headers="headerItems"
            :items="internalItems"
            disable-sort
            :hide-default-footer="true"
          >
            <!-- 指示作成状況メッセージ -->
            <template v-slot:[`item.message`]="{ item }">
              <span>
                <!-- 指示作成状況メッセージを表示 -->
                {{ item.message }}
              </span>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--ボタン領域-->
        <div class="search-row-exeBtn">
          <div class="btn-area">
            <!-- 閉じるボタン -->
            <v-btn class="other-btn" @click="close" :disabled="isDisabledCloseBtn">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { i18n } from "@/lang/lang.js";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import { postParameter } from "@/assets/scripts/js/PostParameter";
import { httpClient } from "@/assets/scripts/js/HttpClient";

export default {
  name: "QueInfoDialog",
  props: {
    // 後処理
    deferredMethod: {
      type: Function,
      default: null,
    },
  },
  inject: ["errorMessage", "loadingSpinner"],
  data: () => ({
    // オープンフラグ
    isOpen: false,
    // リスト
    internalItems: [],
    // 閉じるボタンフラグ
    isDisabledCloseBtn: false,
    // キュー状況ヘッダー
    headerItems: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "message",
        align: "left",
        width: "15 % ",
      },
    ],
  }),
  methods: {
    open(params) {
      // 開く時、初期化
      this.isOpen = true;
      this.internalItems = [];
      this.isDisabledCloseBtn = true;
      // キュー状況ダイアログデータ
      this.internalItems = [
        {
          message: i18n.tc("label.lbl_messageStart"),
        },
      ];
      // キュー管理情報登録
      const body = httpClient.createRequestBodyConfig();
      this.updateParams(body, params);
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      this.postQueInfo(body)
        .then(() => {
          const pollStatus = () => {
            return this.getQueInfoStatus(body).then((status) => {
              if (status == appConfig.RESCOMCODE_SUCCESS) {
                return;
              }
              return new Promise((resolve) => setTimeout(resolve, 5000)).then(pollStatus);
            });
          };
          return pollStatus();
        })
        .catch((ex) => {
          this.internalItems.push({
            message: ex,
          });
        })
        .finally(() => {
          this.internalItems.push({
            message: i18n.tc("label.lbl_messageFinished"),
          });
          this.isDisabledCloseBtn = false;
          // ローディング画面表示OFF
          this.loadingSpinner.counter--;
        });
    },
    close() {
      // 後処理がある場合、実行
      if (this.deferredMethod) {
        this.deferredMethod();
      }
      // 閉じる
      this.isOpen = false;
    },

    // キュー管理情報登録
    postQueInfo(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      return postParameter.postMstQueInfo(params).finally(() => {
        // ローディング画面表示OFF
        this.loadingSpinner.counter--;
      });
    },

    // キュー状況API
    getQueInfoStatus(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 処理
      return new Promise((resolve, reject) => {
        getParameter
          .getMstQueInfo({ requestId: params.reqCom.reqComRequestId })
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resIdv.queInfo.completeFlg == "1") {
              const requestJsonData = JSON.parse(
                jsonData.resIdv.queInfo.queueRequestJson
                  .replace(/\\/g, "")
                  .replace(/:}/g, "}")
                  .replace(/:$/g, "")
              );
              if (requestJsonData.queInfoMessages && requestJsonData.queInfoMessages.length > 0) {
                requestJsonData.queInfoMessages.forEach((message) => {
                  this.internalItems.push({
                    message: message,
                  });
                });
              }
              resolve(appConfig.RESCOMCODE_SUCCESS);
            } else {
              resolve();
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },

    updateParams(body, params) {
      for (const key in params) {
        Object.assign(body[key], params[key]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#QueInfoStatusData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
